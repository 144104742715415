@import "../tokens";

@keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

// $appear-duration: 3000ms
$appear-duration: 3000ms;
$appear-delay: 100ms;

.Quote {
  animation: appear ease-in-out $appear-duration;
  animation-delay: $appear-delay;
  animation-fill-mode: backwards;
  min-height: 100vh;
  font-family: 'Ano Half', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 3.125rem;
  line-height: 3.125rem;

  padding: {
    left: $horiz-pad;
    right: $horiz-pad; }

  div {
    text-align: center; }

  .quote-statement {
    max-width: 52rem; }

  .quote-author {
    margin-top: 3.125rem; } }
