$card-height: 100vw;
$horiz-pad: 3rem;

$black: #000000;
$white: #ffffff;

$light-gray: #F0F0F0;
$dark-gray: #4A4A4A;

$rhythm: 1.65rem;

// $header-height: $rhythm * 2.5

$sizes: (	0: 0.5263157895rem, 1: 0.5789473684rem, 2: 0.6315789474rem, 3: 1rem,	4: 1.3rem, 5: 1.5789473684rem, 6: 1.8947368421rem, 7: 2.6315789474rem );
$lines: (	0: $rhythm * 1, 1: $rhythm * 1,	2: $rhythm, 3: $rhythm, 4: $rhythm, 5: $rhythm * 1,	6: $rhythm * 1.75, 7: $rhythm * 2);

@mixin font-size($i) {
	font-size: map-get($sizes, $i);
	line-height: map-get($lines, $i); }

@mixin hairline-border {
	& {
		position: relative; }
	&:before {
		content: ' ';
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -1px;
		pointer-events: none;
 } }		// background: repeat-x bottom left url("/images/hairline.svg")
