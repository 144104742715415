.HomeLayout {

  .fixed-element {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden; }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column; } }
