@import '~in-context';
@import '../tokens.sass';

.Message {
  font-family: 'Ano Half', sans-serif;
  margin: 0 auto;
  text-align: center;
  line-height: 150%;

  @include in-context(small only) {
    max-width: 38rem;
    font-size: 2rem;
    min-height: calc(100vh - 68rem);
    padding: 4rem $horiz-pad * 0.5; }

  @include in-context(medium) {
    font-size: 2.5rem;
    max-width: 56rem;
    min-height: calc(100vh - 36rem);
    padding: 4rem $horiz-pad calc(50vh - 12rem) $horiz-pad; }

  @include in-context(large) {
    padding: 4rem 0 calc(50vh - 12rem) 0; } }
