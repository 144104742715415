@import "../tokens";

.MainLogo {
  height: 150vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: {
    left: $horiz-pad;
    right: $horiz-pad; }

  .AlexxAndAnton {
    position: sticky;
    top: calc(50vh - 2rem);
    margin: 4rem auto 2rem;

    width: 864px; } }
