@import '~in-context';
@import '../tokens.sass';

@keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

$appear-duration: 1000ms;

.Piece {
  width: 100%;
  height: 100vh;
  position: relative;
  // overflow: hidden

  .image {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity ease-in-out 500ms;
    animation: appear ease-in-out $appear-duration;

    img {
      object-fit: cover;
      width: 100%;
      height: 100vh; } }

  &.isHidden {
    .image {
      opacity: 0; } } }
