@import '~in-context';
@import './tokens.sass';

@keyframes fadein {
  0% {
    background: initial; }
  100% {
    background: #E6E4E5; } }

html {
  animation: fadein linear 1000ms;
  animation-fill-mode: forwards;
  background: initial;
  font-family: 'Ano Half';

  @include in-context(small only) {
    font-size: 10px; }
  @include in-context(medium only) {
    font-size: 12px; }
  @include in-context(large) {
    font-size: 16px; } }
