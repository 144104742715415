@import '~in-context';
@import "../tokens";

.Footer2 {
  h5, p {
    font-weight: normal;
    font-size: 0.75rem;
    margin: {
      top: 0;
      bottom: 1rem; }

    @include breakpoint(small only) {
      font-size: 1rem;
      margin-bottom: 1rem; } }

  p, a, a:link, a:active, a:visited, a:hover {
    color: $dark-gray;
    text-decoration: none; }

  a:hover {
    text-decoration: underline; }

  .instagram {
    flex-grow: 1;

    p {
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center; }

      svg {
        margin-right: 0.5rem; } } }

  .footer-layout {
    display: flex;

    @include in-context(small only) {
      flex-direction: column;
      padding: $horiz-pad * 1.25 $horiz-pad * 0.75; }

    @include in-context(medium) {
      flex-direction: row;
      padding: $horiz-pad $horiz-pad / 2; } }

  //
  // Responsive

  @include in-context(small) {
    .footer-item {
      margin-bottom: $horiz-pad / 2;

      &:last-child {
        margin-bottom: 0; } } }

  @include in-context(medium) {
    .footer-item {
      margin-bottom: 0;

      margin: {
        right: $horiz-pad; }

      &:last-child {
        right: 0; }

      *:last-child {
        margin-bottom: 0; } } }

  @include in-context(large) {
    .footer-item {
      margin: {
        right: $horiz-pad * 2; }

      &:last-child {
        right: 0; } } } }
